import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import lodash from "lodash";
import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { classNames, wrapClick } from "utils";
import { read, utils } from "xlsx";
import { IMeterShipmentFormSchema, MetersInfoFormSchema } from "./schema";

export interface BulkUploadInstallationServiceOrdersProps {
  handleNext: (values: IMeterShipmentFormSchema["metersInfo"]) => void;
  handlePrevious: () => void;
  initialValues: IMeterShipmentFormSchema["metersInfo"];
  values: IMeterShipmentFormSchema;
  handleCancel: () => void;
}

const headerMap = {
  sn: "SN",
  presetAmount: "Preset Amount",
  batchId: "Batch ID",
  orgId: "Org ID",
  cosemLogicalDeviceName: "COSEM Logical Device Name",
  mfgSerialNumber: "MFG Serial Number",
  customerSerialNumber: "Customer Serial Number",
  manufacturer: "Manufacturer",
  modelType: "Model Type",
  ipAddress: "IP Address",
  gprsModuleSerialNumber: "GPRS Module Serial Number",
  firmwareType: "Firmware Type",
  firmwareVersion: "Firmware Version",
  llsSecret: "LLS Secret",
  hlsSecret: "HLS Secret",
  authentication: "Authentication",
  encryptionKey: "Encryption Key",
  macAddress: "MAC",
  badgeId: "Badge ID",
};

const BulkUploadInstallationServiceOrders: FC<
  BulkUploadInstallationServiceOrdersProps
> = ({ initialValues, handleNext, handlePrevious, handleCancel }) => {
  const form = useFormik<IMeterShipmentFormSchema["metersInfo"]>({
    initialValues,
    validationSchema: MetersInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });
  const columns = Object.keys(headerMap);

  const processData = (dataString: any) => {
    // console.log(dataString)
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    // console.log("HEADERS", dataStringLines)
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj: any = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === "\"") d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === "\"") d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    const data = list.map((el) => {
      const item = lodash.keys(headerMap)?.map((column, idx) => {
        const obj: any = {};
        const keyValue = lodash.get(headerMap, column);
        if (column === "presetAmount") {
          obj[column] = el[keyValue] ? lodash.toNumber(el[keyValue]) : null;
        } else {
          obj[column] = el[keyValue];
        }
        return obj;
      });
      return lodash.merge({}, ...item);
    });
    form.setFieldValue("meterSystemDetails", data);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    const file = acceptedFiles[0];
    // console.log("---FILE---", file)
    // setMembers(file)
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt?.target?.result;
      const wb = read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = utils.sheet_to_csv(ws, {});
      processData(data);
    };
    reader.readAsBinaryString(file);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open: openSelect,
  } = useDropzone({
    onDrop,
    multiple: false,
    // accept: ".csv, .xlsx, .xls, .json",
    accept: {
      "text/csv": [".csv"],
      "text/xlsx": [".xlsx"],
      "text/xls": [".xls"],
      "text/json": [".json"],
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      {/* {JSON.stringify(form.errors)} */}
      {!form.isValid && (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex  justify-between mt-4 bg-red-100 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                <span>Errors Detected</span>
                <ChevronUpIcon
                  className={`${!open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-red-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2  text-sm text-gray-500 overflow-y-scroll bg-red-100">
                <div className="overflow-y-">
                  {lodash.isArray(form.errors?.meterSystemDetails) ? (
                    form?.errors?.meterSystemDetails?.map((row, rowIndex) => (
                      <div className="text-red-700">
                        <span className="font-bold">Row: {rowIndex + 1}</span>
                        {lodash.isString(row) ? (
                          <span>{row}</span>
                        ) : (
                          <div className="flex flex-col">
                            {row?.sn ? <li>{row?.sn}</li> : null}
                            {row?.presetAmount ? <li>{row?.presetAmount}</li> : null}
                            {row?.batchId ? <li>{row?.batchId}</li> : null}
                            {row?.orgId ? <li>{row?.orgId}</li> : null}
                            {row?.cosemLogicalDeviceName ? <li>{row?.cosemLogicalDeviceName}</li> : null}
                            {row?.mfgSerialNumber ? <li>{row?.mfgSerialNumber}</li> : null}
                            {row?.customerSerialNumber ? <li>{row?.customerSerialNumber}</li> : null}
                            {row?.manufacturer ? <li>{row?.manufacturer}</li> : null}
                            {row?.modelType ? <li>{row?.modelType}</li> : null}
                            {row?.ipAddress ? <li>{row?.ipAddress}</li> : null}
                            {row?.gprsModuleSerialNumber ? <li>{row?.gprsModuleSerialNumber}</li> : null}
                            {row?.firmwareType ? <li>{row?.firmwareType}</li> : null}
                            {row?.firmwareVersion ? <li>{row?.firmwareVersion}</li> : null}
                            {row?.llsSecret ? <li>{row?.llsSecret}</li> : null}
                            {row?.hlsSecret ? <li>{row?.hlsSecret}</li> : null}
                            {row?.authentication ? <li>{row?.authentication}</li> : null}
                            {row?.encryptionKey ? <li>{row?.encryptionKey}</li> : null}
                            {row?.macAddress ? <li>{row?.macAddress}</li> : null}
                            {row?.badgeId ? <li>{row?.badgeId}</li> : null}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <span>{form?.errors?.meterSystemDetails}</span>
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      <div
        className={classNames(
          !acceptedFiles.length ? "p-6" : "p-0",
          "flex-1 flex flex-col bg-white text-left overflow-y-auto transform transition-all"
        )}
      >
        {!acceptedFiles.length && (
          <button
            type="button"
            {...getRootProps()}
            className="relative flex-1 block rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No file selected
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Kindly select a file by clicking the button above.
            </p>
            <input
              {...getInputProps()}
              id="members"
              name="members"
              type="file"
              className="sr-only"
            />
          </button>
        )}


        {acceptedFiles.length > 0 && (
          <div className="my-6">
            <table className="min-w-full divide-y divide-gray-200 overflow-x-scroll">
              <thead className="bg-gray-50 sticky top-0 z-10 border-b">
                <tr>
                  {columns?.map((column: any, idx: number) => (
                    <th
                      scope="col"
                      key={idx}
                      className={classNames(
                        idx === 0 ? "sticky left-0 bg-gray-50" : "",
                        "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                      )}
                    >
                      {lodash.get(headerMap, column)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {form?.values?.meterSystemDetails?.map((member, key) => {
                  return (
                    <tr key={key}>
                      {columns?.map((column, idx) => {
                        return (
                          <td
                            key={idx}
                            className={classNames(
                              idx === 0 ? "sticky left-0 bg-gray-50" : "",
                              "px-6 py-4 whitespace-nowrap text-sm ",
                              "text-gray-500"
                            )}
                          >
                            {lodash.get(member, column, "N/A")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        {!!acceptedFiles.length && (
          <button
            type="button"
            onClick={wrapClick(openSelect)}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Select New File
          </button>
        )}
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default BulkUploadInstallationServiceOrders;
